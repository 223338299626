import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { CustomEase } from 'gsap/CustomEase';
import { CustomBounce } from 'gsap/CustomBounce';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CustomEase, CustomBounce);

jQuery(window).load(function(){ 
    if($('body').hasClass('home')) {
        // ScrollSmoother.create({
        //     smooth: 1.2,               // how long (in seconds) it takes to "catch up" to the native scroll position
        //     effects: true,           // looks for data-speed and data-lag attributes on elements
        //     smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        //   });

          var title = $('.js-title-fade');
          gsap.fromTo(title, {
            x: -80,
            ease: "power3.in",
            delay: 0.5,            
            duration: 1.2,            
          }, {
            stagger: 0.3,
            autoAlpha: 1,
            x: 0,
          });

          var triggers = $('.home-grid-item');
          let i = 0;
          [...triggers].forEach((trigger) => {
                let j = i%3;
                var animateBlock  = $(trigger).children(); 
                const homeTL = gsap.timeline({                       
                    scrollTrigger: {
                        trigger: trigger,            
                        start: "top bottom",                                                                            
                    },                    
                });
                let delayTime;
                if(i == 1) {
                    delayTime = 1;
                } else {
                    delayTime = j*0.2;
                }
                homeTL.to(animateBlock, {
                    autoAlpha: 1,
                    delay: delayTime,
                    duration: 1.2,
                    ease: "power4.in",
                })
                i++;
          });          
    }

    //tab url links
    if (window.location.href.indexOf("#tab-content") > -1) {
        console.log('hi');
        var urlTab = window.location.href.substring(window.location.href.indexOf("#tab-content"));
        const target = document.querySelector("a[href='" + urlTab + "']");
        console.dir('target: ' + target);
        const parent = target.parentNode;
        const grandparent = parent.closest('.tabs');


        grandparent.querySelectorAll('[aria-selected="true"]').forEach(t => t.setAttribute("aria-selected", false));
        grandparent.querySelectorAll('.active').forEach(t => t.classList.remove("active"));
        // Set this tab as selected
        target.setAttribute("aria-selected", true);
        // Hide all tab panels
        // grandparent.querySelectorAll('[role="tabpanel"]').forEach(p => p.setAttribute("hidden", true));
        // Remove 'active' class on all tab panels
        grandparent.querySelectorAll('[role="tabpanel"]').forEach(q => q.classList.remove("active"));
        // Remove 'active' class on all tabs
        grandparent.querySelectorAll('[role="presentation"]').forEach(r => r.classList.remove("active"));
        // Show the selected panel
        // console.info(target.getAttribute("aria-controls"));
        // console.info(grandparent);
        // console.info(grandparent.parentNode);
        grandparent.parentNode.querySelector(`#${target.getAttribute("aria-controls")}`).style.opacity = 1;

        // Add 'active' class on selected tab and tab panel
        grandparent.parentNode.querySelector(`#${target.getAttribute("aria-controls")}`).classList.add("active");
        target.classList.add("active");

    }
    //newsletter title spans 
    if($('#newsletter-subscribe').length) {
        var element = $('.newsletter .first-line');
        var titleSplit1 = $('.newsletter .first-line').text().split(" ");
        
        // titleSplit1.forEach(function(item) {            
        //     item.append("<span></span>");
        // })
        
        element.empty().html(function() {
            for (let i = 0; i < titleSplit1.length; i++) {
                if (i == 0) {
                    jQuery(this).append('<span class="js-title">' + titleSplit1[i] + '</span>');
                } else {
                    jQuery(this).append(' <span class="js-title">' + titleSplit1[i] + '</span>');
                }
            }
        });   
        var element2 = $('.newsletter .second-line');
        var titleSplit2 = $('.newsletter .second-line').text().split(" ");
        
        // titleSplit1.forEach(function(item) {            
        //     item.append("<span></span>");
        // })
        
        element2.empty().html(function() {
            for (let i = 0; i < titleSplit2.length; i++) {
                if (i == titleSplit2.length - 2) {
                    jQuery(this).append(' <span class="js-title highlight background-pink color-darkBlue">' + titleSplit2[i] + '</span>');
                } else if(i == titleSplit2.length - 1) {
                    jQuery(this).append('<span class="js-title highlight background-pink color-darkBlue">' + titleSplit2[i] + '</span>');
                } else {
                    jQuery(this).append(' <span class="js-title">' + titleSplit2[i] + '</span>');
                }
            }
        });   
    }

    // page title spans {
    if($('.banner-default-highlight').length) {
        var element = $('.default-page__title .title-highlighter');
        var titleSplit1 = $('.default-page__title .title-highlighter').text().split(" ");
        element.empty().html(function() {
            for (let i = 0; i < titleSplit1.length; i++) {
                if (i == 0) {
                    jQuery(this).append('<span class="js-title">' + titleSplit1[i] + '</span>');
                } else {
                    jQuery(this).append(' <span class="js-title">' + titleSplit1[i] + '</span>');
                }
            }
        });   
    }
    

    //menu Animation shapes 
    $('.drop-opener').click(function() {
        // $('.drops-holder .icon-drop-container').children().css('opacity', 0)
        setTimeout(function() {
            if($('.drops-holder').hasClass('show-content')) {                                
                var thisDrop = $('.drops-holder').find('.active-drop');
                
                CustomBounce.create("myBounce", {strength:0.5, squash:0});
                const menuTL = gsap.timeline({       
                    ease: "power4.in",
                });
                if(thisDrop.attr('id') == 'drop2') {
                    menuTL.fromTo(thisDrop.find('span.background-lightBlue'), {
                        autoAlpha: 0,
                        y: -200,
                        rotate: 23,
                        duration: 0.5
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        rotate: 0,
                        duration: 1.2,
                        ease: "myBounce"
                    }
                    )
                    menuTL.fromTo(thisDrop.find('svg.circle-red'), {
                        autoAlpha: 0,
                        y: -150,
                        x: 40,
                        rotate: 0,                        
                        
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        x: 40,
                        duration: 0.6,
                        ease: "power3.in"
                    }, ">-=0.55")
                    menuTL.fromTo(thisDrop.find('svg.circle-red'), {
                        x: 40,
                        rotate: 0,
                        duration: 0.3
                    }, {
                        x: 0,
                        rotate: -360
                    }, ">")
                    menuTL.fromTo(thisDrop.find('span.background-brightBlue'), {
                        autoAlpha: 0,
                        y: -100,
                        rotate: 13,
                        duration: 0.2
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        // rotate: 13,
                    }, ">-=0.5")                    
                    menuTL.fromTo(thisDrop.find('span.background-brightBlue'), {
                        rotate: 13,
                    }, {
                        rotate: -15,
                        ease: "myBounce",
                        duration: 1.5
                    }, ">-=0.2")
                }
                if(thisDrop.attr('id') == 'drop3') {
                    menuTL.fromTo(thisDrop.find('span.background-brightGreen.color-darkBlue'), {
                        autoAlpha: 0,
                        transformOrigin: 'bottom left',
                        rotate: -85,                        
                    }, {
                        autoAlpha: 1,
                        transformOrigin: 'bottom left',
                        rotate: 0,
                        duration: 0.8,
                        ease: "myBounce"
                    })
                    menuTL.fromTo(thisDrop.find('svg.triangle-pink'), {
                        autoAlpha: 0,
                        y: -150,                        
                        transformOrigin: 'bottom',
                        rotate: -59,                        
                        duration: 0.4
                    }, {
                        autoAlpha: 1,
                        y: 0,
                    }, ">-=0.5")
                    menuTL.fromTo(thisDrop.find('svg.triangle-pink'), {
                        transformOrigin: 'bottom',
                        rotate: -59,
                        duration: 0.3
                    }, {
                        // x: 0,
                        rotate: 0
                    }, ">-=0.1")
                    menuTL.fromTo(thisDrop.find('svg.play-pale-green'), {
                        autoAlpha: 0,
                        y: -150,
                        x: -20,
                        rotate: -80,
                        duration: 0.3
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        x: -20,
                        rotate: -80,
                    }, ">-=0.2")
                    menuTL.fromTo(thisDrop.find('svg.play-pale-green'), {
                        transformOrigin: 'center',
                        rotate: -80,
                        duration: 0.3
                    }, {
                        x: 0,
                        rotate: 0
                    }, ">-=0.2")
                    menuTL.fromTo(thisDrop.find('span.background-mint.rounded-edge.color-darkBlue'), {
                        autoAlpha: 0,
                        y: -100,
                        rotate: -11,
                        duration: 0.5
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        rotate: -4,
                    }, ">-=0.6")                    
                    menuTL.fromTo(thisDrop.find('span.background-mint.rounded-edge.color-darkBlue'), {
                        rotate: -4,
                        // autoAlpha: 0,
                    }, {
                        // autoAlpha: 1,
                        rotate: 9,
                        ease: "myBounce",
                        duration: 1.5
                    }, ">-=0.2")
                }
                if(thisDrop.attr('id') == 'drop4') {
                    menuTL.fromTo(thisDrop.find('span.background-lightPink.rounded-edge.color-darkBlue'), {
                        autoAlpha: 0,
                        y: 0,
                        transformOrigin: 'bottom right',
                        rotate: 75,
                        
                    }, {
                        y:0,
                        autoAlpha: 1,
                        transformOrigin: 'bottom right',
                        duration: 0.9,
                        ease: "myBounce",  
                        rotate: 0,
                    })
                    menuTL.fromTo(thisDrop.find('svg.equalise-aqua'), {
                        autoAlpha: 0,
                        y: -150,
                        x: -60,                        
                        rotate: -119,                        
                        duration: 0.3
                    }, {
                        autoAlpha: 1,
                        y: -30,
                        x: -60,

                    }, ">-=0.6")
                    menuTL.fromTo(thisDrop.find('svg.equalise-aqua'), {                        
                        rotate: -119,  
                        x: -60,
                        y: -30,
                        duration: 0.3
                    }, {
                        x: 0,
                        y: 0,
                        rotate: 0
                    }, ">-=0.2")
                    menuTL.fromTo(thisDrop.find('svg.yellow-triangle'), {
                        autoAlpha: 0,
                        y: -150,
                        x: -20,
                        rotate: -35,
                        duration: 0.3
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        x: -20,
                        rotate: -35,
                    }, ">-=0.5")
                    menuTL.fromTo(thisDrop.find('svg.yellow-triangle'), {
                        transformOrigin: 'center',
                        rotate: -35,
                        duration: 0.1
                    }, {
                        x: 0,
                        rotate: -15
                    }, ">-=-.1")
                    menuTL.fromTo(thisDrop.find('span.background-pink.color-darkBlue'), {
                        autoAlpha: 0,
                        y: -100,
                        rotate: 0,
                        duration: 0.3
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        rotate: 0,
                    }, ">-=0.6")                    
                    menuTL.fromTo(thisDrop.find('span.background-pink.color-darkBlue'), {
                        rotate: 0,
                        // autoAlpha: 0,
                    }, {
                        // autoAlpha: 1,
                        rotate: -10,
                        ease: "myBounce",
                        duration: 1.5
                    }, ">-=0.2")
                }
                if(thisDrop.attr('id') == 'drop5') {
                    menuTL.fromTo(thisDrop.find('svg.star-pale-red'), {
                        autoAlpha: 0,
                        x: -200,                        
                        rotate: -375,                        
                    }, {
                        x:0,
                        autoAlpha: 1,                        
                        duration: 0.9,                        
                        rotate: 0,
                    })                    
                    menuTL.fromTo(thisDrop.find('span.background-aqua.rounded-edge.color-darkBlue'), {
                        autoAlpha: 0,
                        y: -100,
                        rotate: -19,
                        duration: 0.2
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        rotate: -19,
                    }, ">-=0.6")                    
                    menuTL.fromTo(thisDrop.find('span.background-aqua.rounded-edge.color-darkBlue'), {
                        rotate: -19,
                        // autoAlpha: 0,
                    }, {
                        // autoAlpha: 1,
                        rotate: 16,
                        ease: "myBounce",
                        duration: 1.5
                    }, ">-=0.2")
                }
            } else {
                $('.icon-drop-container').children().css('opacity', '0');
            }
        }, 600)    
    })

    //banner Animations
    var triggers = $('.banner-default-highlight');
    [...triggers].forEach((trigger) => {
        var title = $(trigger).find('.js-title-banner');
        var greenCircle = $(trigger).find('.banner-icons .circle-green');
        var greenForward = $(trigger).find('.banner-icons .forward-pale-green');
        var pinkStar = $(trigger).find('.banner-icons .star-pink');
        var triangleGreen = $(trigger).find('.banner-icons .triangle-pale-green');
        var greenOnAir = $(trigger).find('.banner-icons .on-air-green');
        var blueSquare = $(trigger).find('.banner-icons .square-pale-blue');
        var orangeTriangle = $(trigger).find('.banner-icons .triangle-orange');
        var blueEqualiser = $(trigger).find('.banner-icons .equaliser-blue');
        var bluePlay = $(trigger).find('.banner-icons .play-pale-blue');
        const bannerTL = gsap.timeline({        
            scrollTrigger: {
                trigger: trigger,            
                start: "top center+=300", 
                delay: 0.9                           
            },                    
        });
        CustomBounce.create("bannerBounce", {strength:0.5, squash:0});
        if(title.length) {
            bannerTL.fromTo(title, {
                x: -80,
                ease: "power3.in",                          
                duration: 1.2,     
                autoAlpha: 0,       
                }, {
                stagger: 0.3,
                delay: 0.5,  
                autoAlpha: 1,
                x: 0,                
            }, "")    
        }
        if(greenCircle.length) {
            bannerTL.fromTo(greenCircle, {
                y: -150,
                x: -70,
                ease: "power3.in",                          
                duration: 1.2,     
                autoAlpha: 0,   
                rotate: -80,    
            }, {
                y: 0,
                x: -70,
                rotate: -80,
                autoAlpha: 1
            }, ">")    
            bannerTL.fromTo(greenCircle, {                
                x: -70,       
                y: 0,         
                ease: "power3.in",                                                          
                rotate: -80,
                transformOrigin: 'center'                   
            }, {
                duration: 0.9,  
                x: 0,       
                y: 0,         
                rotate: 0,
            }, ">")    
        }
        if(pinkStar.length) {
            bannerTL.fromTo(pinkStar, {
                y: -250,
                x: 30,
                ease: "power3.in",                          
                duration: 1.2,     
                autoAlpha: 0,   
                rotate: -80,    
            }, {
                y: 0,
                x: 30,
                rotate: 180,
                autoAlpha: 1
            }, "<")    
            bannerTL.fromTo(pinkStar, {                
                x: 30,       
                y: 0,         
                ease: "power3.in",                                                          
                rotate: 90,
                transformOrigin: 'center'                   
            }, {
                duration: 0.9,  
                x: 0,       
                y: 0,         
                rotate: 0,
            }, ">")    
        }
        if(greenForward.length) {
            bannerTL.fromTo(greenForward, {
                y: -190,                                                                           
                autoAlpha: 0,   
                rotate: -180,    
            }, {
                y: 0,                
                rotate: 0,
                autoAlpha: 1,
                duration: 1.4,  
                ease: "bannerBounce"
            }, "<")  
        }
        if(triangleGreen.length) {
            bannerTL.fromTo(triangleGreen, {
                y: -120,                                                                           
                autoAlpha: 0,   
                rotate: 110,    
            }, {
                y: 0,                
                rotate: 0,
                autoAlpha: 1,
                duration: 1.2,  
                ease: "bannerBounce"
            }, "<+=0.6")  
        }
        if(greenOnAir.length) {
            bannerTL.fromTo(greenOnAir, {
                y: -200,                                                                           
                autoAlpha: 0,   
                rotate: 20,    
            }, {
                y: 0,                
                rotate: 0,
                autoAlpha: 1,
                duration: 0.6,  
                ease: "bannerBounce"
            }, ">-=0.2")  
        }
        if(blueSquare.length) {
            bannerTL.fromTo(blueSquare, {
                y: -150,
                x: -70,
                ease: "power3.in",                          
                duration: 1.2,     
                autoAlpha: 0,   
                rotate: -80,    
            }, {
                y: 0,
                x: -70,
                rotate: -80,
                autoAlpha: 1
            }, ">")    
            bannerTL.fromTo(blueSquare, {                
                x: -70,       
                y: 0,         
                ease: "power3.in",                                                          
                rotate: -80,
                transformOrigin: 'center'                   
            }, {
                duration: 0.5,  
                x: 0,       
                y: 0,         
                rotate: 0,
            }, ">")    
        }
        if(blueEqualiser.length) {
            bannerTL.fromTo(blueEqualiser, {
                y: -190,                  
                autoAlpha: 0,   
                rotate: 90,    
            }, {
                y: 0,                
                rotate: -80,
                autoAlpha: 1,
                ease: "bannerBounce",                          
                duration: 1.2,   
            }, ">-=0.6")  
        }
        if(orangeTriangle.length) {
            bannerTL.fromTo(orangeTriangle, {
                y: -190,                  
                autoAlpha: 0,   
                rotate: -80,    
            }, {
                y: 0,                
                rotate: -80,
                autoAlpha: 1,
                ease: "power4.in",
                transformOrigin: "-2px 42px",            
                duration: 0.9,   
            }, ">-=0.9")  
            bannerTL.fromTo(orangeTriangle, {                                                 
                rotate: -80,    
            }, {                
                rotate: 0,                
                ease: "bannerBounce",
                transformOrigin: "-2px 42px",            
                duration: 0.7,   
            }, ">")  
        }
        if(bluePlay.length) {
            bannerTL.fromTo(bluePlay, {
                y: -220,   
                x: 60,               
                autoAlpha: 0,   
                rotate: 117,    
            }, {
                y: -36,    
                x: 60,            
                rotate: -80,
                autoAlpha: 1,
                rotate: 117,    
                ease: "power3.in",                          
                duration: 0.6,   
            }, ">-=0.6")  
            bannerTL.fromTo(bluePlay, {                               
                x:60,
                y:-36,
                rotate: 117,    
            }, {    
                x: 0,
                y: 0,            
                rotate: 0,                
                ease: "power2.in",                
                duration: 0.7,   
            }, ">")  
        }
    });

    //loadingAnimation 
    var triggers = $('section');
    [...triggers].forEach((trigger) => {
        var title = $(trigger).find('.js-title');
        var fadeIn = $(trigger).find('.js-fade-in');
        var fadeUp = $(trigger).find('.js-fade-up');
        var fadeDown = $(trigger).find('.js-fade-down');
        var fadeLeft = $(trigger).find('.js-fade-left');
        var fadeRight = $(trigger).find('.js-fade-right');
        var fadeInRandom = $(trigger).find('.js-fade-in-random');        
        let delay ;
        
        const animationTL = gsap.timeline({        
            scrollTrigger: {
                trigger: trigger,            
                start: "top center+=300",                            
            },                    
        });
        if(title.length) {
            animationTL.fromTo(title, {
                x: -80,
                ease: "power3.in",
                delay: 0.5,            
                duration: 1.2,     
                autoAlpha: 0,       
                }, {
                stagger: 0.3,
                autoAlpha: 1,
                x: 0,                
            }, "")    
        }
        if(fadeIn.length) {
            animationTL.from(fadeIn, {
                opacity: 0, 
                autoAlpha: 0,
                stagger: 0.4,           
                duration: 1.8,        
                ease: "power4.inOut"
            }, ">")    
        }
        if(fadeInRandom.length) {
            animationTL.from(fadeInRandom, {
                opacity: 0, 
                autoAlpha: 0,
                stagger: {                        
                    amount: 1.3,
                    from: "random"
                },         
                duration: 1.2,        
                ease: "power4.inOut"
            }, ">")    
        }
        if(fadeUp.length) {
            animationTL.from(fadeUp ,{
                autoAlpha: 0,            
                duration: 1,
                y: "40%",
                stagger: 0.5,
                ease: "power4.inOut"
            }, ">");   
        }
        if(fadeDown.length) {
            animationTL.from(fadeDown ,{
                autoAlpha: 0,            
                duration: 1.5,
                y: "-80%",
                stagger: 0.5,
                ease: "power4.inOut"
            }, ">");   
        }
        if(fadeLeft.length) {
            animationTL.from(fadeLeft,{
                autoAlpha: 0,            
                duration: 1.4,
                stagger: 0.8,
                x: "100%",
                ease: "power4.inOut"
            }, "<");
        }
        if(fadeRight.length) {
            animationTL.from(fadeRight,{
                autoAlpha: 0,            
                duration: 1.4,
                stagger: 0.8,
                x: "-100%",
                ease: "power4.inOut"
            }, "<");
        }

    })

    //tuningin ticker tape 
    if($('.tickertape').length) {
        var speed = 50;


        
          $('ul.tickertape li').wrapAll('<span class="ticker-items">');
          var tickerWidth = $('ul.tickertape').width();
          var spanWidth = $('ul.tickertape span').width();
          $('ul.tickertape span').clone().appendTo('ul.tickertape');
          $('ul.tickertape span').wrapAll('<span class="ticker-wrapper">');
        
          gsap.set( '.ticker-wrapper' , {x: tickerWidth } );
        
        
        
          // v = d/t ... 
          // speed = moveX / duration ... so, 
          // duration = moveX / speed
        
          // This is the inital tween from off-screen (right) to the starting point (0)
          // So its duration is based on the width of the visible ticker area
          var initDuration = tickerWidth/speed;
        
          // This is the tween of the entire span holding the text from 0 to -itsWidth
          // So its duration is based on the width of one span
          var loopDuration = spanWidth/speed;
        
          var tl = new gsap.timeline();
              tl.to( '.ticker-wrapper' , { x: 0,  ease: 'none', duration: initDuration } );
              tl.to( '.ticker-wrapper' , { x: -spanWidth,  ease: 'none', repeat: -1, duration: loopDuration } );    

    }

    //footer animation hover 
    if($('.footer__icons').length) {
        $('.footer__icons .shape a').hover(function() {
            $($( this ).closest('.footer__icons')).addClass('hovering');            
          }, function() {
            $($( this ).closest('.footer__icons')).removeClass('hovering');
          });
        }        

});